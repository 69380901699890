// AdminRoot.jsx
import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Header from '../../layout/admin/Header/header';
import Footer from '../../layout/admin/Footer/footer';
import { verifyToken } from '../../utils/auth';
import { jwtDecode } from 'jwt-decode';

const AdminRoot = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuth = async () => {
            const token = document.cookie.split(';').find(cookie => cookie.trim().startsWith('token='));
            if (!token) {
                navigate('/login');
                return;
            }

            const tokenValue = token.split('=')[1];
            const decodedToken = jwtDecode(tokenValue);
            if (decodedToken.role === 'user') {
                navigate('/employee');
                return;
            }
            // else if(decodedToken.role === 'admin'){
            //     // navigate('/admin');
            //     return;
            // }


            const isValid = await verifyToken(tokenValue);
            if (!isValid) {
                // navigate('/admin');
                return;
            }else(
                navigate(`/home`)
            )

            
        };

        checkAuth();
    }, [navigate]);

    return (
        <div>
            <Header />
            <Outlet/>
            <Footer />
        </div>
    );
};

export default AdminRoot;
