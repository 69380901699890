import React from 'react';
import { Formik } from 'formik';
import axios from 'axios';
import "../add/add.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import api from '../../../api/api';
const RegisterForm = () => (
  <>
  <Helmet></Helmet>
  <div className="add">
    <Formik
      initialValues={{ email: '', password: '', username: '', role: 'user' }}
      validate={values => {
        const errors = {};
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        axios.post(`${api}/register`, values)
          .then((res) => {
            console.log(res.data);
            toast.success("Hesap yaradıldı!");
          })
          .catch((error) => {
            console.error(error);
            toast.error("Hesap yaradıla bilmədi yenidən sınayın.");
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        isSubmitting
      }) => (
        <form className="addform" onSubmit={handleSubmit}>
             <h1 className='text-light'>Register</h1>
          <input
            type="text"
            name="username"
            onChange={handleChange}
            value={values.username}
            placeholder="Username"
          />
          <input
            type="email"
            name="email"
            onChange={handleChange}
            value={values.email}
            placeholder="Email"
          />
          <input
            type="password"
            name="password"
            onChange={handleChange}
            value={values.password}
            placeholder="Password"
          />
          <select
            name="role"
            onChange={handleChange}
            value={values.role}
            required
          >
            <option default value="user">User</option>
            <option value="operator">Operator</option>
            <option value="admin">Admin</option>
          </select>
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Loading..." : "Register"}
          </button>
        </form>
      )}
    </Formik>
    <ToastContainer />
  </div>
  </>
);

export default RegisterForm;
