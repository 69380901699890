import axios from "axios";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import ROUTES from "./Routes/routes";
import MainContext from "./context/context";
import { useEffect, useState } from "react";
import api from "./api/api";
function App() {
    const[loading, setLoading] = useState(true);
    const[error, setError] = useState('false');
    const [data, setdata] = useState([]);
    useEffect(() => {
        axios.get(`${api}/order`).then((res) => {

            setdata(res.data)
            setLoading(false)
        }).catch((err) => {
            setError(err)
            setLoading(false)
        })
    },[])
    const Price = (data) => {
        return ((data.person*20)+(data.teenager*15)+(data.child*0)+(data.food*10))
    }

    const router = createBrowserRouter(ROUTES);
    return (
        <>
        <MainContext.Provider value={{data, setdata, loading, setLoading, error, setError,Price}}>
            <RouterProvider router={router }/>
        </MainContext.Provider>
        </>
    );
}

export default App;
