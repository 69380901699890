import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../../api/api';
import '../dashboard/DailyDashboard.css';


const Users = () => {
    const [user, setuser] = useState([])
    useEffect(() => {
        axios.get(`${api}/users`).then((res) => {
            setuser([...res.data])
        }).catch((err) => {
            console.log(err)
        })
    },[])
    const DeleteItem=(id)=>{
        axios.delete(`${api}/users/${id}`).then((res)=>{
            setuser([...res.data.users])
            toast.success("istifadeci silindi")
        })

    }
    return (
    <>
                <Helmet>
                    <title>Users</title>
                </Helmet>
    <main className='mt-5 pt-5 dashboard'>
        <table className="table mt-5 pt-5">
                <thead>
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">adı</th>
                    <th scope="col">email</th>
                    <th scope="col">password</th>
                    <th scope="col">role</th>
                    <th scope="col">id</th>
                    <th scope="col">Delete</th>
                    
                    </tr>
                </thead>
                <tbody>
                    
                    {
                        user.map((item,index)=>{
                            return(
                                <tr key={index}>
                                    <th scope="row">{index+1}</th>
                                    <td>{item.username}</td>
                                    <td>{item.email}</td>
                                    <td>{item.password}</td>
                                    <td>{item.role}</td>
                                    <td>{item._id}</td>
                                    
                                    <td><button className='btn btn-danger' onClick={()=>DeleteItem(item._id)}>Delete</button></td>
                                    
                                </tr>
                                
                            )
                        })
                    }
                </tbody>
                
                </table>
                <ToastContainer position="top-left"/>
    </main>
    </>
  )
}

export default Users