import axios from 'axios';

export const verifyToken = async (token) => {
  try {
    const response = await axios.post('https://aquarooms-backend.onrender.com/api/verifyToken', { token });
    return response.data.isValid;
  } catch (error) {
    console.error('Token verification error:', error);
    return false;
  }
};
