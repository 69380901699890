import React from 'react';
import { saveAs } from 'file-saver';
import XLSX from 'xlsx-color';

const JsonToExcel = ({ jsonData, fileName }) => {
  const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  const exportToExcel = () => {
    // Prepare data for Excel export
    const dataForExcel = jsonData ? jsonData.map((item) => ({
      'Tarix': new Date(item.time).toLocaleDateString(),
      'Ad Soyad': `${item.name} ${item.surname}`,
      'Böyük Sayı': item.person,
      'Qiymət 1 (20)': 20,
      'Kiçik Sayı': item.teenager,
      'Qiymət 2 (15)': 15,
      'Yemək': item.food,
      'Qiymət 3 (10)': 10,
      'Cəm': item.person + item.teenager + item.food,
      'Ödənilən Ümumi Məbləğ': item.person * 20 + item.teenager * 15 + item.food * 10,
      'Ödenis Qebul Edildi': item.received,
      'Böyük Sayı Gəlir': item.person * (20 - 15),
      'Kiçik Sayı Gəlir': item.teenager * (15 - 10),
      'Yemək Gəlir': item.food * (10 - 6),
      'Toplam Gəlir': item.person * (20 - 15) + item.teenager * (15 - 10) + item.food * (10 - 6),
      'complete': item.complete === true // New column: reservation completion
    })) : [];

    // Filter data for different sheets
    const completedReservations = dataForExcel.filter(item => item.complete === true);
    const ongoingReservations = dataForExcel.filter(item => item.complete === false);

    // Function to create worksheet with styling and totals row
    const createWorksheet = (data, sheetName) => {
      // Calculate totals for the sheet
      const totalsRow = {
        'Tarix': 'Toplam',
        'Ad Soyad': '',
        'Böyük Sayı': data.reduce((acc, item) => acc + (item['Böyük Sayı'] || 0), 0),
        'Qiymət 1 (20)': 20,
        'Kiçik Sayı': data.reduce((acc, item) => acc + (item['Kiçik Sayı'] || 0), 0),
        'Qiymət 2 (15)': 15,
        'Yemək': data.reduce((acc, item) => acc + (item['Yemək'] || 0), 0),
        'Qiymət 3 (10)': 10,
        'Cəm': data.reduce((acc, item) => acc + (item['Cəm'] || 0), 0),
        'Ödenis Qebul Edildi': '',
        'Ödənilən Ümumi Məbləğ': data.reduce((acc, item) => acc + (item['Ödənilən Ümumi Məbləğ'] || 0), 0),
        'Böyük Sayı Gəlir': data.reduce((acc, item) => acc + (item['Böyük Sayı Gəlir'] || 0), 0),
        'Kiçik Sayı Gəlir': data.reduce((acc, item) => acc + (item['Kiçik Sayı Gəlir'] || 0), 0),
        'Yemək Gəlir': data.reduce((acc, item) => acc + (item['Yemək Gəlir'] || 0), 0),
        'Toplam Gəlir': data.reduce((acc, item) => acc + (item['Toplam Gəlir'] || 0), 0),
        'complete': '' // Not needed for totals row
      };

      // Add totals row to the data array
      const dataWithTotals = [...data, totalsRow];

      // Create worksheet from data with totals
      const ws = XLSX.utils.json_to_sheet(dataWithTotals);

      // Set column widths
      const colWidths = [
        { wpx: 100 }, // 'Tarix' column
        { wpx: 200 }, // 'Ad Soyad' column
        { wpx: 100 }, // 'Böyük Sayı' column
        { wpx: 100 }, // 'Qiymət 1 (20)' column
        { wpx: 100 }, // 'Kiçik Sayı' column
        { wpx: 100 }, // 'Qiymət 2 (15)' column
        { wpx: 100 }, // 'Yemək' column
        { wpx: 100 }, // 'Qiymət 3 (10)' column
        { wpx: 100 }, // 'Cəm' column
        { wpx: 150 }, // 'Ödenis Qebul Edildi' column
        { wpx: 150 }, // 'Ödənilən Ümumi Məbləğ' column
        { wpx: 150 }, // 'Böyük Sayı Gəlir' column
        { wpx: 150 }, // 'Kiçik Sayı Gəlir' column
        { wpx: 150 }, // 'Yemək Gəlir' column
        { wpx: 150 }, // 'Toplam Gəlir' column
        { wpx: 100 }   // 'complete' column
      ];

      ws['!cols'] = colWidths;

      // Apply styling to header row
      const headerStyle = {
        font: { bold: true, color: { rgb: "FFFFFF" } },
        fill: { fgColor: { rgb: "FFD700" } }, // Gold background
        alignment: { horizontal: "center", vertical: "center" }
      };

      // Apply header style to each cell in the header row
      const headerRow = Object.keys(dataWithTotals[0]);
      headerRow.forEach((header, index) => {
        const cellAddress = XLSX.utils.encode_cell({ r: 0, c: index });
        if (!ws[cellAddress]) ws[cellAddress] = {};
        ws[cellAddress].s = headerStyle;
      });

      // Apply totals style to the totals row
      const totalsRowIndex = dataWithTotals.length;
      const defaultTotalsRowStyle = {
        font: { bold: true },
        alignment: { horizontal: "center", vertical: "center" }
      };
      const blueStyle = {
        ...defaultTotalsRowStyle,
        fill: { fgColor: { rgb: "34BFDE" } } // Blue background for 'Toplam Gəlir'
      };
      headerRow.forEach((header, index) => {
        const cellAddress = XLSX.utils.encode_cell({ r: totalsRowIndex, c: index });
        if (!ws[cellAddress]) ws[cellAddress] = {};
        ws[cellAddress].s = (header === 'Toplam Gəlir') ? blueStyle : defaultTotalsRowStyle;
      });

      return { ws, sheetName };
    };

    // Create worksheets
    const sheets = [
      createWorksheet(dataForExcel, 'Sheet1'),
      createWorksheet(completedReservations, 'Tamamlananlar'),
      createWorksheet(ongoingReservations, 'Devam Edenler')
    ];

    // Create workbook
    const wb = XLSX.utils.book_new();
    sheets.forEach(sheet => {
      XLSX.utils.book_append_sheet(wb, sheet.ws, sheet.sheetName);
    });

    // Convert workbook to Excel buffer
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const dataBlob = new Blob([excelBuffer], { type: EXCEL_TYPE });
    saveAs(dataBlob, `${fileName}.xlsx`);
  };

  return (
    <button onClick={exportToExcel} className='btn btn-success'>Export to Excel</button>
  );
};

export default JsonToExcel;
