import React from 'react'
import './Qrcode.css'
const NotFoundQr = () => {
  return (
    <main className='QrCodeMain'>
      <div className=' mt-2 d-flex  content'>
      
      <h1>QR code not found</h1>
      </div>
    </main>
  )
}

export default NotFoundQr