// AdminRoot.jsx
import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { verifyToken } from '../../utils/auth';
import { jwtDecode } from 'jwt-decode';

const DetailsRoot = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuth = async () => {
            const token = document.cookie.split(';').find(cookie => cookie.trim().startsWith('token='));
            if (!token) {
                navigate('/login');
                return;
            }

            const tokenValue = token.split('=')[1];
            const decodedToken = jwtDecode(tokenValue);
            if (decodedToken.role === 'user') {
                navigate('/employee');
                return;
            }
          


            const isValid = await verifyToken(tokenValue);
            if (!isValid) {
                console.log("test");
                // navigate('/admin');
                return;
            }else(
                navigate(`/home`)
            )

            
        };

        checkAuth();
    }, [navigate]);

    return (
        <div>
            <Outlet/>
        </div>
    );
};

export default DetailsRoot;
