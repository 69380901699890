import { Formik } from 'formik';
import React from 'react'
import api from '../../api/api';
import axios from 'axios';
import { toast } from 'react-toastify';

const DashboardTR = ({item, index, Price,copyToClipboard,DeleteItem}) => {
  return (
    <tr key={index}>
    <th scope="row">{index + 1}</th>
    <td>{item.name}</td>
    <td>{item.surname}</td>
    <td>{item.phone}</td>
    <td>{Price(item)} Manat</td>
    <td>{item.complete ? "Completed" : "Reserved"}</td>
    <td>
    {new Date(item.createTime).toLocaleString()}
    </td>
    <td>{item.time}</td>
    <td>{item.received}</td>
    <td>
        <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#exampleModal-${item._id}`}>
            links
        </button>
        <div className="modal fade" id={`exampleModal-${item._id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="links">
                            <h3>QR Code:</h3>
                            <div className='copy_component'>
                                <p>{`https://aquarooms.info/qr/${item._id}`}</p>
                                <button onClick={() => copyToClipboard(`https://aquarooms.info/qr/${item._id}`)}>
                                    Kopyala
                                </button>
                            </div>
                            <h3>İdare seyfesi:</h3>
                            <div className='copy_component'>
                                <p>{`https://aquarooms.info/employee/details/${item._id}`}</p>
                                <button onClick={() => copyToClipboard(`https://aquarooms.info/employee/details/${item._id}`)}>
                                    Kopyala
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </td>
    <td><button className='btn btn-danger' onClick={() => DeleteItem(item._id)}>Delete</button></td>
    <td>
        <button type="button" className="btn btn-warning" data-bs-toggle="modal" data-bs-target={`#editModal-${item._id}`}>Edit</button>
        <div className="modal fade" id={`editModal-${item._id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                    <Formik
                            initialValues={{
                                name: item.name,
                                surname: item.surname,
                                phone: item.phone,
                                time: item.time,
                                person: item.person,
                                teenager: item.teenager,
                                child: item.child,
                                food: item.food,
                                complete: item.complete,
                                foodservices: item.foodservices
                            }}
                            validate={values => {
                                const errors = {};
                                // Add any validation logic here if needed
                                return errors;
                            }}
                            onSubmit={(values, { resetForm }) => {
                                axios.put(`${api}/order/${item._id}`, values)
                                    .then((res) => {
                                     
                                        toast.success("Sipariş yenilendi!");
                                        resetForm();
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                        toast.error("bir xeta meydana geldi.");
                                    });
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue
                            }) => (
                                <form onSubmit={handleSubmit} className="addform">
                                    <label>Name</label>
                                    <input
                                        type="text"
                                        name="name"
                                        placeholder='Name'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name}
                                    />
                                    {errors.name && touched.name && errors.name}

                                    <label>Surname</label>
                                    <input
                                        type="text"
                                        name="surname"
                                        placeholder='Surname'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.surname}
                                    />
                                    {errors.surname && touched.surname && errors.surname}

                                    <label>Phone</label>
                                    <input
                                        type="tel"
                                        name="phone"
                                        placeholder='Phone'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.phone}
                                    />
                                    {errors.phone && touched.phone && errors.phone}

                                    <label>Time</label>
                                    <input
                                        type="date"
                                        name="time"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.time}
                                    />
                                    {errors.time && touched.time && errors.time}

                                    <label>Person</label>
                                    <input
                                        type="number"
                                        name="person"
                                        placeholder='Person'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.person}
                                    />
                                    {errors.person && touched.person && errors.person}

                                    <label>Teenager</label>
                                    <input
                                        type="number"
                                        name="teenager"
                                        placeholder='Teenager'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.teenager}
                                    />
                                    {errors.teenager && touched.teenager && errors.teenager}

                                    <label>Child</label>
                                    <input
                                        type="number"
                                        name="child"
                                        placeholder='Child'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.child}
                                    />
                                    {errors.child && touched.child && errors.child}

                                    <label>Food</label>
                                    <input
                                        type="number"
                                        name="food"
                                        placeholder='Food'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.food}
                                    />
                                    {errors.food && touched.food && errors.food}

                                    <label>Status</label>
                                    <input
                                        type="checkbox"
                                        name="complete"
                                        onChange={() => setFieldValue('complete', !values.complete)}
                                        onBlur={handleBlur}
                                        checked={values.complete}
                                    />
                                    {errors.complete && touched.complete && errors.complete}
                                    <label>Food Status</label>
                                    <input
                                        type="checkbox"
                                        name="foodservices"
                                        onChange={() => setFieldValue('foodservices', !values.foodservices)}
                                        onBlur={handleBlur}
                                        checked={values.foodservices}
                                    />
                                    {errors.foodservices && touched.foodservices && errors.foodservices}

                                    <button type="submit" disabled={isSubmitting}>
                                        Submit
                                    </button>
                                </form>
                            )}
                        </Formik>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </td>
</tr>
  )
}

export default DashboardTR