import './add.css';
import React, { useContext, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import MainContext from '../../../context/context';
import { Formik } from 'formik';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../../api/api';

const Add = () => {
    const [result, setResult] = useState({});
    const { setdata, Price } = useContext(MainContext);
    const qrCodeRef = useRef(null);
    const detailsRef = useRef(null);

    const copyToClipboard = (ref) => {
        const textToCopy = ref.current.textContent;
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                console.log('Text copied to clipboard:', textToCopy);
                toast.success("Link copied!");
            })
            .catch(err => console.error('Failed to copy text: ', err));
    };

    return (
        <>
            <Helmet>
                <title>Add</title>
            </Helmet>
            <div className='add'>
                <div className="container">
                    <Formik
                        initialValues={{ name: '', surname: '', phone: '', time: '', person: 1, teenager: 0, child: 0, food: 0 }}
                        validate={values => {
                            const errors = {};
                            // Add any validation logic here if needed
                            return errors;
                        }}
                        onSubmit={(values, { resetForm }) => {
                            axios.post(`${api}/order`, values)
                                .then((res) => {
                                    setdata(res.data);
                                    setResult({ price: Price(values), id: res.data[res.data.length - 1]._id });
                                    toast.success("Order registered successfully!");
                                    resetForm();
                                })
                                .catch((err) => {
                                    console.log(err);
                                    toast.error("An error occurred.");
                                });
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                        }) => (
                            <form onSubmit={handleSubmit} className="addform">
                                <label>Name</label>
                                <input
                                    type="text"
                                    name="name"
                                    placeholder='Name'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name || ''}
                                    required
                                />
                                {errors.name && touched.name && <div className="error">{errors.name}</div>}
                                <label>Surname</label>
                                <input
                                    type="text"
                                    name="surname"
                                    placeholder='Surname'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.surname || ''}
                                    required
                                />
                                {errors.surname && touched.surname && <div className="error">{errors.surname}</div>}
                                <label>Phone</label>
                                <input
                                    type="tel"
                                    name="phone"
                                    placeholder='Phone'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.phone || ''}
                                />
                                {errors.phone && touched.phone && <div className="error">{errors.phone}</div>}
                                <label>Date</label>
                                <input
                                    type="date"
                                    name="time"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.time || ''}
                                    required
                                />
                                {errors.time && touched.time && <div className="error">{errors.time}</div>}
                                <label>Adult</label>
                                <input
                                    type="number"
                                    name="person"
                                    placeholder='Person'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.person || ''}
                                />
                                {errors.person && touched.person && <div className="error">{errors.person}</div>}
                                <label>Teenager</label>
                                <input
                                    type="number"
                                    name="teenager"
                                    placeholder='Teenager'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.teenager || ''}
                                />
                                {errors.teenager && touched.teenager && <div className="error">{errors.teenager}</div>}
                                <label>Newborn</label>
                                <input
                                    type="number"
                                    name="child"
                                    placeholder='Child'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.child || ''}
                                />
                                {errors.child && touched.child && <div className="error">{errors.child}</div>}
                                <label>Food</label>
                                <input
                                    type="number"
                                    name="food"
                                    placeholder='Food'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.food || ''}
                                />
                                {errors.food && touched.food && <div className="error">{errors.food}</div>}
                                <button type="submit" disabled={isSubmitting}>
                                    Submit
                                </button>
                            </form>
                        )}
                    </Formik>
                </div>
                {result.price && (
                    <div className="links">
                        <h3>Price: {result.price}</h3>
                        <h3>QR Code:</h3>
                        <div className='copy_component'>
                            <p ref={qrCodeRef}>{`https://aquarooms.info/qr/${result.id}`}</p>
                            <button onClick={() => copyToClipboard(qrCodeRef)}>
                                Copy
                            </button>
                        </div>
                        <h3>Admin Page:</h3>
                        <div className='copy_component'>
                            <p ref={detailsRef}>{`https://aquarooms.info/employee/details/${result.id}`}</p>
                            <button onClick={() => copyToClipboard(detailsRef)}>
                                Copy
                            </button>
                        </div>
                    </div>
                )}
            </div>
            <ToastContainer />
        </>
    );
}

export default Add;
