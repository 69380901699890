
import AdminRoot from "../pages/admin/AdminRoot"
import Dashboard from "../pages/admin/dashboard/dashboard"
import Add from "../pages/admin/add/add"
import Error from "../pages/error/error/error"
import SiteRoot from "../pages/site/SiteRoot"
// import Home from "../pages/site/home/home"
import Details from "../pages/admin/details/Details"
import Qrcode from "../pages/site/QrCode/Qrcode"
import LoginForm from "../pages/admin/login/LoginForm"
import RegisterForm from "../pages/admin/register/RegisterForm"
import Users from "../pages/admin/users/Users"
import DetailsRoot from "../pages/worker/DetailsRoot"
import WorkerHome from "../pages/worker/workerhome/WorkerHome"
import NotFoundQr from "../pages/site/QrCode/NotFoundQr"
import NotfoundDetail from "../pages/admin/details/NotfoundDetail"


const ROUTES =[
    {
        path:"/",
        element:<SiteRoot/>,
        children:[
            
            {
                path:"",
                element:<LoginForm/>
            },
            {
                path:"not-found-qr",
                element:<NotFoundQr/>
            },
            {
                path:"qr/:id",
                element:<Qrcode/>
            },
            {
                path:"login",
                element:<LoginForm/>
            }
            ,
            {
                path:"admin/login",
                element:<LoginForm/>
            }
        ]
    },
    {
        path:"/admin",
        element: <AdminRoot/>,
        children:[
            {
                path:"",
                element:<Dashboard/>
            },
            {
                path:"add",
                element:<Add/>
            },
            {
                path:"details/:id",
                element:<Details/>
            },
            {
                path:"register",
                element:<RegisterForm/>
            }
            ,{
                path:"users",
                element:<Users/>
            }
        ]
    }
    ,    {
        path:"*",
        element:<Error/>,
        
    },
    {
        path:"/employee",
        element: <DetailsRoot/>,
        children:[
            {
                path:"",
                element:<WorkerHome/>
            },
            {
                path:"details/:id",
                element:<Details/>
            },
            {
                path:"not-found-detail",
                element:<NotfoundDetail/>
            }
        ]
    }
]
export default ROUTES;