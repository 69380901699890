import React, { useContext, useEffect, useState } from 'react';
import './Details.css';
import { useNavigate } from 'react-router-dom';
import MainContext from '../../../context/context';
import {  useParams } from 'react-router';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import api from '../../../api/api';
import { jwtDecode } from 'jwt-decode';
const Details = () => {
  const { setdata, data, Price } = useContext(MainContext);
  const [detail, setDetail] = useState({});
  const { id } = useParams();
  const [receiver,setReceiver]=useState('')
  const navigate = useNavigate();




  useEffect(() => {
    axios.get(`${api}/order/${id}`)
      .then((res) => {
        setDetail({ ...res.data });
      })
      .catch((error) => {
        navigate('/employee/not-found-detail');
        console.error("Error fetching order details:", error);
      });

      const ReceivedName = async () => {
        const token = document.cookie.split(';').find(cookie => cookie.trim().startsWith('token='));
        const tokenValue = token.split('=')[1];
        const decodedToken = jwtDecode(tokenValue)
        console.log(decodedToken);
        setReceiver(decodedToken.username)
        
    };

    ReceivedName();
  }, [id, data,navigate]);

  const setComplete = (id) => {
    axios.put(`${api}/order/${id}`, { complete: true ,received:`${receiver}`})
      .then((res) => {
        setdata(res.data);
        setDetail(res.data.find(order => order._id === id));
        console.log(detail);
        toast.success("Sipariş tamamlandı!");
      })
      .catch((error) => {
        console.error("Error updating order:", error);
      });
  };
  const setFoodComplate = (id) => {
    axios.put(`${api}/order/${id}`, { foodservices: true })
      .then((res) => {
        setdata(res.data);
        setDetail(res.data.find(order => order._id === id));
        console.log(detail);
        toast.success("Xidmət tamamlandı!");
      })
      .catch((error) => {
        console.error("Error updating order:", error);
      });
  };

  return (
    <>
    <Helmet>
      <title>Details</title>
    </Helmet>
    <main className='detail'>
      <div className="container pt-5">
        <div className="detail__section">
          <h1>Details</h1>
          <h3>Ad: {detail.name}</h3>
          <h3>Soyad: {detail.surname}</h3>
          <h3>Telefon: {detail.phone}</h3>
          <h3>Qiymet: {Price(detail)} AZN</h3>
          <h3>Giriş Sayı: </h3>
          <h3>Boyuk: {detail.person} Nəfər</h3>
          {detail.teenager > 0 && <h3>Yeniyetmə: {detail.teenager} Nəfər</h3>}
          {detail.child > 0 && <h3>Korpə: {detail.child} Nəfər</h3>}
          <h3>Status: {detail.complete ? "Tamamlandı"  : "Gözlemede"}</h3>
          <h3>Siparis saatı: {detail.time}</h3>
          <h3>Yemək : {detail.food}</h3>
          <h3>Yemək Servis : {detail.foodservices === true ? "Tamamlandı"  : "Gözlemede"}</h3>
          <button className='btn btn-success' onClick={() => setFoodComplate(detail._id)}>Yemək Tamamla</button>
          <button className='btn btn-warning' onClick={() => setComplete(detail._id)}>Siparişi Tamamla</button>
        </div>
      </div>
      <ToastContainer />
    </main>
    </>
  );
}

export default Details;
