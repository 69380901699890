import React, { useEffect, useState } from 'react';
import './WorkerHome.css';
import {jwtDecode} from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

const WorkerHome = () => {
  const [employee, setEmployee] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkEmployee = () => {
      const token = document.cookie.split(';').find(cookie => cookie.trim().startsWith('token='));
      if (token) {
        const tokenValue = token.split('=')[1];
        const decodedToken = jwtDecode(tokenValue);
        setEmployee(decodedToken);
      } else {
        navigate('/login');
      }
    };

    checkEmployee();
  }, [navigate]);

  const logout = () => {
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 GMT";
    navigate('/login');
  };

  return (
    <main className='workerHome'>
      <div className="container">
        <section className='workerContent text-light'>
          <h2 className=''>Employer Home</h2>
          <div className="">
            {employee && (
              <>
                <h5>Qeydiyatdan keçmiş olan işçi :</h5>
                <h2 className='text-info'>{employee.username}</h2>
                <h5>Position:</h5>
                <h2 className='text-info'>{employee.role === 'admin' ? 'Admin' : 'Employee'}</h2>
                <h4>Artıq qr kodu oxuya bilərsiniz</h4>
              </>
            )}
          </div>
          <button onClick={logout} className='btn btn-danger'>Logout</button>
        </section>
      </div>
    </main>
  );
};

export default WorkerHome;
