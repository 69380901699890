import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import MainContext from '../../../context/context';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../../api/api';
import DashboardTR from '../../../components/dashboardTR/dashboardTR';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import DailyDashboard from './DailyDashboard'; // Import DailyDashboard component
import JsonToExcel from '../../../components/ExcelConvater/jsontoexcel';
import './DailyDashboard.css';

const Dashboard = () => {
    const { data, setdata, Price } = useContext(MainContext);

    const DeleteItem = (id) => {
        axios.delete(`${api}/order/${id}`).then((res) => {
            setdata([...res.data]);
            toast.success("Sipariş silindi");
        });
    };

    const copyToClipboard = (str) => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(str)
                .then(() => {
                    toast.success("Link kopyalandı!");
                })
                .catch(err => {
                    console.error('Metin kopyalanamadı: ', err);
                });
        } else {
            console.error('Tarayıcı panoya erişimi desteklemiyor');
        }
    };

    const [search, setSearch] = useState();

    const Search = (str) => {
        const filteredData = str === '' ? null : data.filter(item => item.name.toLowerCase().includes(str.toLowerCase()));
        setSearch(filteredData);
        console.log(filteredData);
    };

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            <main className='mt-5 pt-5 dashboard'>
                <div className="search w-75">
                    <div className="searcbar ">
                        <input
                            className='p-1 ms-3'
                            type="text"
                            onChange={(e) => {
                                Search(e.target.value)
                            }}
                            placeholder='Search'
                        />
                    </div>
                    
                    {search ?
                        <div>
                            <h5>Neticeler:</h5>
                            <table className="table ">
                                <tbody>
                                    {search.length !== 0 ?
                                        search.map((item, index) => (
                                            <DashboardTR item={item} index={index} DeleteItem={DeleteItem} copyToClipboard={copyToClipboard} Price={Price} />
                                        )) : <>netice tapilmadi</>
                                    }
                                </tbody>
                            </table>
                        </div> : <></>
                    }
                </div>
                    <Box sx={{ width: '100%', overflowX: 'scroll' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', alignItems: 'center' }}>
                            <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            aria-label="basic tabs example"
                            sx={{ flexGrow: 1, '.MuiTabs-scroller': { overflowX: 'scroll' } }}
                            >
                            <Tab label="All" {...a11yProps(0)} />
                            <Tab label="Reserved" {...a11yProps(1)} />
                            <Tab label="Completed" {...a11yProps(2)} />
                            <Tab label="Missing" {...a11yProps(3)} />
                            <Tab label="Daily Rezervasyon" {...a11yProps(4)} />
                            <JsonToExcel jsonData={data} fileName="ExportedFile" />
                        </Tabs>
                    </Box>
                    {value === 0 && (
                        <table className="table mt-5 pt-3">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Adı</th>
                                    <th scope="col">Soyadı</th>
                                    <th scope="col">Telefon</th>
                                    <th scope="col">Toplam Meblağ</th>
                                    <th scope="col">Durum</th>
                                    <th scope="col">Sipariş Saati</th>
                                    <th scope="col">Ayrıldığı Gün</th>
                                    <th scope="col">Qarşıladı</th>
                                    <th scope="col">Linkler</th>
                                    <th scope="col">Sil</th>
                                    <th scope="col">Düzenle</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, index) => (
                                    <DashboardTR
                                        key={index}
                                        item={item}
                                        index={index}
                                        Price={Price}
                                        DeleteItem={DeleteItem}
                                        copyToClipboard={copyToClipboard}
                                    />
                                ))}
                            </tbody>
                        </table>
                    )}
                    {value === 1 && (
                        <table className="table mt-5 pt-3">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Adı</th>
                                    <th scope="col">Soyadı</th>
                                    <th scope="col">Telefon</th>
                                    <th scope="col">Toplam Meblağ</th>
                                    <th scope="col">Durum</th>
                                    <th scope="col">Sipariş Saati</th>
                                    <th scope="col">Ayrıldığı Gün</th>
                                    <th scope="col">Linkler</th>
                                    <th scope="col">Sil</th>
                                    <th scope="col">Düzenle</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.filter(item => item.complete === false).map((item, index) => (
                                    <DashboardTR
                                        key={index}
                                        item={item}
                                        index={index}
                                        Price={Price}
                                        DeleteItem={DeleteItem}
                                        copyToClipboard={copyToClipboard}
                                    />
                                ))}
                            </tbody>
                        </table>
                    )}
                    {value === 2 && (
                        <table className="table mt-5 pt-3">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Adı</th>
                                    <th scope="col">Soyadı</th>
                                    <th scope="col">Telefon</th>
                                    <th scope="col">Toplam Meblağ</th>
                                    <th scope="col">Durum</th>
                                    <th scope="col">Sipariş Saati</th>
                                    <th scope="col">Ayrıldığı Gün</th>
                                    <th scope="col">Linkler</th>
                                    <th scope="col">Sil</th>
                                    <th scope="col">Düzenle</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.filter(item => item.complete === true).map((item, index) => (
                                    <DashboardTR
                                        key={index}
                                        item={item}
                                        index={index}
                                        Price={Price}
                                        DeleteItem={DeleteItem}
                                        copyToClipboard={copyToClipboard}
                                    />
                                ))}
                            </tbody>
                        </table>
                    )}
                    {value === 3 && (
                        <table className="table mt-5 pt-3">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Adı</th>
                                    <th scope="col">Soyadı</th>
                                    <th scope="col">Telefon</th>
                                    <th scope="col">Toplam Meblağ</th>
                                    <th scope="col">Durum</th>
                                    <th scope="col">Sipariş Saati</th>
                                    <th scope="col">Ayrıldığı Gün</th>
                                    <th scope="col">Linkler</th>
                                    <th scope="col">Sil</th>
                                    <th scope="col">Düzenle</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.filter(item => !item.complete && new Date(item.time) < new Date()).map((item, index) => (
                                    <DashboardTR
                                        key={index}
                                        item={item}
                                        index={index}
                                        Price={Price}
                                        DeleteItem={DeleteItem}
                                        copyToClipboard={copyToClipboard}
                                    />
                                ))}
                            </tbody>
                        </table>
                    )}
                    {value === 4 && (
                        <DailyDashboard
                            data={data} 
                            Price={Price}
                            DeleteItem={DeleteItem}
                            copyToClipboard={copyToClipboard}
                        />
                    )}
                </Box>
                <ToastContainer position="top-left" />
            </main>
        </>
    );
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default Dashboard;
