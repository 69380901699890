import React from 'react';
import { Formik } from 'formik';
import axios from 'axios';
import "../add/add.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import { Helmet } from 'react-helmet';
import api from '../../../api/api';
const LoginForm = () => {
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  return (
    <>
    <Helmet>
      <title>Login</title>
    </Helmet>
    <div className="add">
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            axios.post(`${api}/login`, values)
              .then((res) => {
                document.cookie = `token=${res.data.token}; max-age=3600; path=/`;
                localStorage.setItem('token', res.data.token);
                toast.success("Logged in successfully!");
                navigate("/admin");
              })
              .catch((error) => {
                console.error('Login error:', error);
                toast.error("Invalid email or password");
              })
              .finally(() => {
                setSubmitting(false);
              });
          }, 400);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other props */
        }) => (
          <form className="addform" onSubmit={handleSubmit}>
            <h1 className='text-light'>Login</h1>
            <input
              type="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              placeholder="Email"
              autoComplete="username"
            />
            {errors.email && touched.email && <div className="error text-danger">{errors.email}</div>}
            <input
              type="password"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              placeholder="Password"
              autoComplete="current-password"
            />
            {errors.password && touched.password && <div className="error text-danger">{errors.password}</div>}
            <button type="submit" disabled={isSubmitting}>
              Login
            </button>
          </form>
        )}
      </Formik>
      <ToastContainer />
    </div>
    </>
  );
};

export default LoginForm;
