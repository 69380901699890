import React from 'react'

const NotfoundDetail = () => {
    return (
        <main className='detail'>
        <div className="container pt-5">
        <div className="detail__section">
        <h1>Page not found</h1>
        </div>
        </div>
    </main>
    )
}

export default NotfoundDetail