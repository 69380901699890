import React, { useEffect, useState } from 'react'
import QRCode from 'react-qr-code';
import {useParams } from 'react-router';
import './Qrcode.css'
import axios from 'axios';
import {  useNavigate, Link } from 'react-router-dom';
import api from '../../../api/api';
const Qrcode = () => {
  const navigate = useNavigate();
  const {id }=useParams()
  const [qr,setqr]=useState({})
  useEffect(() => {
    axios.get(`${api}/order/${id}`)
      .then((res) => {
        setqr({ ...res.data });
      })
      .catch((error) => {
        navigate('/not-found-qr');
        console.error("Error fetching order details:", error);
      });
  }, [id, navigate]);

  return (
    <main className='QrCodeMain'>
      <div className=' mt-2 d-flex  content'>
        <div className="qr bg-light p-2">
        <QRCode 
        value={`https://aquarooms.info/employee/details/${id}`}
        size={300}
        
        bgColor='#ffffff' 
        fgColor='#000000'
        />
        </div>
        <div className="info ">
          <h5>Name: <span className='text-light'>{qr.name}</span></h5>
          <h5>Status :{qr.complete ? <span className='text-success'>Tamamlandı</span >:<span className='text-warning'>Rezervasya edilib</span>}</h5>
        </div>   
        <div className="info">
          <h5>Valid for: </h5>
          <div className="links m-0">
          <span>{qr.person} Adult</span> {qr.teenager ===0 ? <></>:<span>{qr.teenager} Child</span>} {qr.child ===0 ? <></>:<span>{qr.child} Newborn</span>}
          </div>
        </div>
        <div className="info">
          <h5>Food Service:</h5>
          <div className="links m-0">
            <span>number of meals:{qr.food }</span>
            <span>Service {qr.foodservices === true ? <span className='text-success'>"was provided"</span> : <span className='text-warning'>"was reserved"</span> }</span>
          </div>
        </div>
        <div className=" info">
          <h5>Open-Close:</h5>
          <div className="links m-0">
            <span>10:00 - 18:00</span>
          </div>
        </div>
        <div className="contact info">
          <h5>Contact:</h5>
          <div className="links m-0">
            <div className='d-flex gap-4 d-flex align-items-center' ><i class="fa-solid fa-phone"> :</i>  
              <div className="d-flex  flex-column">
                    <Link to="tel:+994997117998"  className='p-1'>  +994 99-711-79-98</Link>
                    <Link to="tel:+994997117997"  className='p-1'>  +994 99-711-79-97</Link>
              </div>
            </div>
            <div className='d-flex gap-4 d-flex align-items-center text-Whatsapp' ><i class="fa-brands fa-whatsapp"> :</i> 
              <div className="d-flex  flex-column">
                <Link to="https://wa.me/+994997117998" className='p-1 text-Whatsapp'>+994 99-711-79-98</Link> 
                <Link to="https://wa.me/+994997117997" className='p-1 text-Whatsapp'>+994 99-711-79-97</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="location info">
          <h5>Location:</h5>
          <div className="links m-0">
            <Link to="https://maps.app.goo.gl/R8MxyhxRdEi1bYB48"  className='link'> <span><i class="fa-solid fa-location-dot"></i> : AF Hotel & Aqua Park <br />
            Novxanı qəsəbəsi, Dənizkənarı, Bakı 0119</span></Link>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Qrcode