import React from 'react';
import DashboardTR from '../../../components/dashboardTR/dashboardTR';
import './DailyDashboard.css';
const groupByDate = (data) => {
  return data.reduce((acc, item) => {
    const date = new Date(item.time).toLocaleDateString(); // Tarihi yerel tarih biçiminde alın
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(item);
    return acc;
  }, {});
};

const calculateTotalPeople = (items) => {
  return items.reduce((total, item) => {
    return total + item.person + item.teenager;
  }, 0);
};

const DailyDashboard = ({ data, Price, DeleteItem, copyToClipboard }) => {
  const filteredData = data.filter(item => !item.complete);
  const groupedData = groupByDate(filteredData);

  // Function to compare dates for sorting in descending order
  const compareDatesDesc = (dateA, dateB) => {
    const dateObjA = new Date(dateA);
    const dateObjB = new Date(dateB);
    if (dateObjA > dateObjB) return -1;
    if (dateObjA < dateObjB) return 1;
    return 0;
  };

  return (
    <div>
      {Object.keys(groupedData)
        .sort(compareDatesDesc) // Sort dates in descending order (most recent to oldest)
        .map(date => {
          const itemsForDate = groupedData[date];
          const totalPeople = calculateTotalPeople(itemsForDate);
          return (
            <div key={date}>
               {/* Her gün için kişi sayısını göster */}
              <details className='DailyDetail' >
                <summary className='DailySummary'><h3>{date} - {totalPeople} people</h3></summary>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Adı</th>
                    <th scope="col">Soyadı</th>
                    <th scope="col">Telefon</th>
                    <th scope="col">Toplam Meblağ</th>
                    <th scope="col">Durum</th>
                    <th scope="col">Sipariş Saati</th>
                    <th scope="col">Ayrıldığı Gün</th>
                    <th scope="col">Linkler</th>
                    <th scope="col">Sil</th>
                    <th scope="col">Düzenle</th>
                  </tr>
                </thead>
                <tbody>
                  {itemsForDate.map((item, index) => (
                    <DashboardTR
                      key={item.id || index}
                      item={item}
                      index={index}
                      Price={Price}
                      DeleteItem={DeleteItem}
                      copyToClipboard={copyToClipboard}
                    />
                  ))}
                </tbody>
              </table>
              </details>
            </div>
          );
        })}
    </div>
  );
};

export default DailyDashboard;
