
import React from 'react'
import { Link } from 'react-router-dom'
import './header.css'
const Header = () => {
    const Logout=()=>{
        document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 GMT";
        window.location.reload();
    }
    
    return (
        <header className='headerAdmin'>
            <nav className="navbar navbar-expand-lg ">
    <div className="container-fluid">
        <Link className="navbar-brand" href="/admin">AquaRooms</Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
            <Link className="nav-link active" aria-current="page" to="/">Home</Link>
            </li>
            <li className="nav-item">
            <Link className="nav-link" to="/admin">Admin</Link>
            </li>
            <li className="nav-item">
            <Link className="nav-link" to="/admin/add">Add</Link>
            </li>
            <li className="nav-item">
            <Link className="nav-link" to="/admin/Register">Register</Link>
            </li>
            <li className="nav-item">
            <Link className="nav-link" to="/admin/users">Users</Link>
            </li>

        </ul>

        <button className="btn btn-primary" onClick={Logout}>Logout</button>
        </div>
    </div>
    </nav>
        </header>
    )
}

export default Header
                                